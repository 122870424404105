<template>
  <Button
    icon="fa fa-question-circle"
    class="p-button-text p-button-rounded text-white"
    @click="toggle"
  />
  <Menu ref="menu" :model="items" :popup="true" />
  <Dialog
    v-model:visible="about"
    modal
    :draggable="false"
    dismissableMask
    :closable="false"
  >
    <template #default>
      <div class="px-4">
        <h3 class="mb-2">Application Inventaire {{ version }} (Dashboard)</h3>
        <p class="text-sm">
          Propulsé par <strong>Groupe Bourdoncle (KreatikArt)</strong>
        </p>
        <p class="text-sm">
          Développeur <strong>Kenji Davong</strong><br />
          UI/UX <strong>PrimeVue / PrimeFlex</strong>
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-content-end">
        <Button label="OK" @click="about = false" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref } from "vue";
import { version } from "../../package.json";

export default {
  setup() {
    const about = ref(false);
    const menu = ref();
    const items = ref([
      {
        label: "A propos",
        command: () => {
          about.value = true;
        },
      },
    ]);
    const toggle = (event) => {
      menu.value.toggle(event);
    };

    return { menu, items, about, toggle, version };
  },
};
</script>

<style lang="scss" scoped></style>
