<template>
  <div class="grid-layout">
    <Sidebar v-model="sidebar"></Sidebar>
    <div class="default">
      <NavBar @click="sidebar = !sidebar" :sidebar="sidebar"></NavBar>
      <main class="main p-3">
        <router-view></router-view>
      </main>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import { ref } from "vue";

export default {
  components: { NavBar, Footer, Sidebar },
  setup() {
    const sidebar = ref(false);

    return { sidebar };
  },
};
</script>

<style lang="scss" scoped>
.grid-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-flow: column;
}
.main {
  overflow-y: scroll;
}
.default {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}
</style>
