<template>
  <footer class="footer">
    <span @click="openAbout" class="cursor-pointer"
      >Application Inventaire - v{{ version }}</span
    >
    <Dialog
      v-model:visible="displayAbout"
      :dismissable-mask="true"
      :close-on-escape="true"
      :modal="true"
      :draggable="false"
      header="A propos"
    >
      <div class="m-4">
        <p class="text-lg font-bold mb-0">Application Inventaire</p>
        <p class="font-semibold mt-0">Version {{ version }}</p>
        <p class="text-sm">
          Développé par
          <a href="https://www.kreatikart.com" target="_blank">Kreatik Art</a>
        </p>
      </div>
    </Dialog>
  </footer>
</template>

<script>
import { version } from "../../package.json";
import { ref } from "vue";

export default {
  setup() {
    const displayAbout = ref(false);

    const openAbout = () => {
      displayAbout.value = true;
    };

    return { version, openAbout, displayAbout };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: auto;
  text-align: right;
  font-size: 0.75rem;
  height: 1rem;
  color: $secondary-light;
  padding: 0 0.5rem;
}
</style>
