<template>
  <nav class="nav-bar">
    <Button
      :icon="sidebar ? 'fa fa-chevron-left' : 'fa fa-bars'"
      @click="$emit('on-click')"
    />
    <router-link custom v-slot="{ navigate }" :to="{ name: 'Homepage' }">
      <Button
        icon="fa fa-home"
        class="p-button-text mr-auto text-white"
        @click="navigate"
      />
    </router-link>
    <Button
      icon="pi pi-fw pi-power-off"
      @click="onLogout($event)"
      label="Se deconnecter"
      icon-pos="right"
    />
    <About />
  </nav>
</template>

<script>
import useAuth from "../composables/useAuth";
import { defineComponent } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useRouter } from "vue-router";

import About from "./_About";

export default defineComponent({
  emits: ["on-click"],
  props: {
    sidebar: {
      type: Boolean,
    },
  },
  components: { About },
  setup() {
    const confirm = useConfirm();
    const { logout } = useAuth();
    const router = useRouter();

    const onLogout = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Voulez-vous vous deconnecter?",
        icon: "pi pi-fw pi-power-off",
        acceptClass: "p-button-danger",
        rejectClass: "p-button-secondary p-button-text",
        accept: async () => {
          await logout();
          await router.replace({ name: "Login" });
        },
      });
    };

    return { onLogout };
  },
});
</script>

<style lang="scss" scoped>
.nav-bar {
  height: 56px;
  padding: 0 0.5rem;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
