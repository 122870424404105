<template>
  <PanelMenu :model="items" class="custom-sidebar" v-if="items" />
</template>

<script>
import getMenu from "./_menu";

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup(props, { emit }) {
    const router = useRouter();

    router.afterEach((to, from, next) => {
      emit("close");
      next();
    });

    const items = ref(null);

    onMounted(async () => {
      items.value = await getMenu();
    });

    return { items };
  },
};
</script>
