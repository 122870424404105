<template>
  <div
    class="sidebar"
    :class="modelValue ? 'open' : 'close'"
    @mouseenter="$emit('update:modelValue', true)"
    @mouseleave="$emit('update:modelValue', false)"
  >
    <div class="header" :class="modelValue ? 'wide' : ''">
      <Avatar
        icon="pi pi-user"
        :size="modelValue ? 'large' : 'small'"
        shape="circle"
      />
      <div
        v-if="modelValue"
        class="flex flex-column align-items-center display-name"
      >
        <span>{{ user.displayName }}</span>
      </div>
    </div>
    <SidebarItems
      v-if="modelValue"
      @close="$emit('update:modelValue', false)"
    />
    <div class="panel-text" v-show="!modelValue">Menu</div>
  </div>
</template>

<script>
import { projectAuth } from "../firebase/config";
import SidebarItems from "./_SidebarItems";

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  components: { SidebarItems },
  setup() {
    const user = projectAuth.currentUser;

    return { user };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $secondary;
  transition: width 150ms ease-in-out;
  &.open {
    width: 240px;
  }
  &.close {
    width: 48px;
  }
}

@media screen and (max-width: 375px) {
  .sidebar.close {
    width: 0;
  }
}

.header {
  height: 48px;
  transition: all 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px $secondary-light;
  &.wide {
    height: 112px;
    background-color: $secondary-dark;
  }
}

.display-name {
  font-size: 0.85rem;
  color: white;
  margin-top: 1rem;
  transition: all 150ms ease;
}

.panel-text {
  color: rgba(white, 0.2);
  text-transform: uppercase;
  transform: rotate(-90deg);
  margin: 1.5rem 0;
  letter-spacing: 1px;
  font-weight: 300;
  cursor: default;
  user-select: none;
}

.p-avatar {
  transition: all 150ms;
}

::v-deep(.p-panelmenu) {
  * {
    color: white !important;
  }
  .p-panelmenu-header > a {
    background: transparent !important;
    border: none;
    border-bottom: solid 1px $secondary-light !important;
    &[aria-expanded="true"] {
      border-bottom-color: transparent;
    }
  }
  .p-menuitem-icon {
    min-width: 24px;
    text-align: center;
  }
  .p-panelmenu-content {
    padding: 0;
    border-radius: 0;
    border: none;
  }
  .p-submenu-list {
    background-color: $secondary-dark !important;
    border: none;
    font-size: 0.85rem;
    &:focus {
      box-shadow: none;
    }
  }
  a.p-menuitem-link[role="treeitem"] {
    padding-left: 2rem;
  }
  .p-panelmenu-icon.pi {
    &.pi-chevron-down,
    &.pi-chevron-right {
      order: 3;
      margin-left: auto;
    }
  }
  a {
    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      outline: none;
      box-shadow: none !important;
      background-color: $secondary-light !important;
    }
  }
}
</style>
